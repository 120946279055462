import marinkoPhoto from './Assets/Marinko.png';
import dankoPhoto from './Assets/Danko.jpg';
import vincPhoto from './Assets/Vinc.jpg';
import marioPhoto from './Assets/Mario.jpg';
import alenPhoto from './Assets/Alen.png';

export interface Member {
    name: string,
    phone?: string,
    rawDescription: string,
    photo: string,
}

export const members: {
        title: string,
        subtitle: string,
        members: Member[]
    } = {
        title: 'O nama',
        subtitle: 'Pripremite se za upoznavanje najvećih bilogorskih bećara',
        members: [
            {
                name: 'Marinko',
                phone: '098/564-010',
                rawDescription: 'Umirovljeni policajac sa profesionalnom deformacijom: sve mora biti po pravilu službe. Kako je najjači član banda, svi ga se boje pa je obično sve po njegovom. U slobodno vrijeme (od jedne do druge penzije) se bavi ugostiteljstvom: Kaja u Kupinovcu kultno je mjesto obožavatelja razularene zabave, žestokog alkohola i elektronskog pikada. Od roditelja je naslijedio obiteljsku kuću i umiljati, nježan glas, pa je prvi vokal benda (pogotovo u tamburaškoj pratnji u svatovima).',
                photo: marinkoPhoto
            },
            {
                name: 'Danko',
                phone: '091/112-1031',
                rawDescription: 'Veteran komercijalne glazbe, jedini član orkestra sa visokim klasičnim glazbenim obrazovanjem od tri godine osnovne glazbene škole – smjer violina. Kako je rastao, promijenio je i instrument, pa sad svira berdu. Ostali članovi banda ga trpe zbog blage naravi <strike>i zato što još uvijek nije ostao bez vozačke dozvole</strike>. U stvari, nikome nije jasno zašto je još uvijek član banda. Valjda zato što stalno čeprka po internetu i oglašava Vivu kao bend za svadbu, bend za svatove, bend za krstitke, bend za godišnjice mature, glazbu za vjenčanja i postavlja druge slične tagove.',
                photo: dankoPhoto
            },
            {
                name: 'Vinc',
                rawDescription: 'Višegodišnji multidisciplinarni veteran: osim dugogodišnjeg sviranja lakoglazbenih nota, puno je poznatiji kao veteran dugih špediterskih linija i prevoznika tura lakokvarljivih tehničkih proizvoda za našeg starog Peveca, no najbolje se snalazi među friško oborenim trupcima stoljetnih hrastova i drugih, jeftinijih vrsta drveća na obroncima Bilogore, Moslavine, Papuka i drugih slavonskih brda. Lako ga možete prepoznati po narančastom kamionu, uobičajenom pretovaru i karakterističnoj prebrzoj vožnji od lijeve do desne bankine... Kad ne vozi, dečec pjeva, svira klavijaturu, harmoniku, akustičnu gitaru i engleski rog i ne namjerava prestati tako skoro.',
                photo: vincPhoto
            },
            {
                name: 'Mario',
                rawDescription: 'Dečko je mlad, lijep i obećava. Vlasnik najviše lajkova našeg profila na fejsu, na užas njegove <strike>novopečene</strike> žene Martine koja je inače puna razumijevanja, a naš Marek ljubavi za nju. Trenutno svira da zaradi za obnovu tastove kleti i vinograda u Majurcu. Kao višegodišnji zaposlenik klaonice, do sad je naučio razliku između vratine i paštete, a kaže da su mu najteže table carskog mesa. Obično najduže putuje na svirku, pa ima još jednu manu - ne pije.',
                photo: marioPhoto
            },
            {
                name: 'Alen',
                rawDescription: 'Neprikosnoveni vladar porječja Ilove i Pakre, čuvar močvara i plaža rijeke Česme. Najjužnije locirani član orkestra čija je dužnost održavanje ritma i pozitivnih vibracija. Kronično neispavan, pa koristi svaku minutu da ubije oko.',
                photo: alenPhoto
            },
        ]
    }
;
